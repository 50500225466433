/**
 * SendingEmailToGyms.js
 * 
 * This file helps connect back-end to CRM 
 * in terms of sending emails
 */

import { onboardingSuccessEmailContent } from "../../assets/Emails/OnboardingEmail";
import { cloud_url } from "../../assets/links";

/**
 * Send Onboarding Success Email to Gyms
 * @param   {String} email      -   Email of the gym
 * @param   {String} gymName    -   Name of the gym
 * @param   {String} link       -   Link Of the gym
 */
export const sendOnboardingMail = async(email, gymName, link = '') => {
    try {
        // API call to send the email via the specified method
        const response = await fetch(cloud_url + '/sendEmailRoute', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                toEmail     :   email,
                subject     :   `Thank you for collaborating with TRAIN REX`,
                content     :   onboardingSuccessEmailContent(email, gymName, link)
            }),
        });

        if (response.ok) {
            // console.log(`${sendMethod.toUpperCase()} sent successfully to ${to}`);
            return {status : true};
        } else {
            const errorMessage = await response.text();
            // console.error(`Failed to send email to user. Status: ${response.status}, Error: ${errorMessage}`);
            return {
                status      :   false,
                errorMsg    :   `${errorMessage} : ${gymName}`,
            };
        }
    } catch (error) {
        return {
            status      :   false,
            errorMsg    :   `${error} : ${gymName}`,
        };
    }
}