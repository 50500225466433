/**
 * OnboardingEmail.js
 * 
 * This is the email which is to be sent to the gyms
 * For completing the onboarding process with Train Rex
 */

/**
 * Generates an email template for gyms onboarding on the website.
 * @param   {String} email      -   The email address of the gym.
 * @param   {String} gymName    -   The name of the gym being onboarded.
 * @param   {String} link       -   The connection link of the gym
 * @returns {String}            -   The formatted email content.
 */
export const onboardingSuccessEmailContent = (email, gymName, link = '') => {
    return `
Dear ${gymName} Team,

Thank you for choosing to list ${gymName} with Train Rex! We are thrilled to have your gym as part of our fitness community. Your participation helps us bring fitness enthusiasts closer to discovering the best facilities and classes tailored to their needs.

By listing your gym with us, you gain access to a wider audience of fitness enthusiasts who are eager to explore what ${gymName} has to offer. Together, we aim to create a healthier, more connected community.

You are also eligible for a free one month trial of our SMART CONTROL PANEL. Visit - https://trainrex.org .

Should you have any questions, need assistance, or want to make updates to your gym profile, feel free to reach out to us at info@trainrex.org. We’re here to ensure your experience with Train Rex is seamless and rewarding.

Once again, thank you for trusting us with your fitness journey. We look forward to helping ${gymName} thrive in the Train Rex ecosystem!

Best regards,

The Train Rex Team

Email       :   ${email}
Gym Link    :   https://trainrexofficial.com${link}
    `;
}